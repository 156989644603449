import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { AppleSSOButton } from "./AppleSSOButton";
import { GoogleSSOButton } from "./GoogleSSOButton";

interface LogInFormProps {
  title: string;
  emailLabel?: string;
  showContainer?: boolean;
  handleEmailLogin: (autoSelected: boolean) => void;
  handleAppleLogin: () => void;
  handleGoogleLogin: () => void;
}

const isFBorIGBrowserUserAgent = (userAgent: string): boolean => {
  return userAgent.includes("FBAN") ||
    userAgent.includes("FB_IAB") ||
    userAgent.includes("Instagram");
};

export const LogInForm = ({
  title,
  emailLabel = "Continue with Email",
  showContainer = true,
  handleEmailLogin,
  handleAppleLogin,
  handleGoogleLogin,
}: LogInFormProps) => {
  const [renderSSO, setRenderSSO] = useState(false);

  const singleSignOnDisabled = (): boolean => {
    return typeof window !== "undefined" &&
      isFBorIGBrowserUserAgent(window.navigator.userAgent);
  };

  const handleEmailClick = () => {
    handleEmailLogin(singleSignOnDisabled());
  };

  useEffect(() => {
    if (singleSignOnDisabled()) {
      handleEmailClick();
    } else {
      setRenderSSO(true);
    }
  }, []);

  return (
    <FormContainer showContainer={showContainer}>
      { renderSSO && (
        <>
          <Title2>{title}</Title2>
          <AppleSSOButton text="Continue with Apple" onClick={handleAppleLogin} />
          <GoogleSSOButton text="Continue with Google" onClick={handleGoogleLogin} />
          <EmailButton onClick={handleEmailClick}>{emailLabel}</EmailButton>
        </>
      )}
    </FormContainer>
  );
};

const Title2 = styled.h2`
  margin-top: 0;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  font-family: ${theme.SECONDARY_FONT};
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-bottom: 24px;
    font-size: 18px;
  }
`;

const FormContainer = styled.div<{ showContainer: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  min-height: 460px;
  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  padding-bottom: 120px;
  border-radius: 16px;
  background-color: ${props => props.showContainer ? "#fafafa" : "transparent"};
  box-shadow: ${props => props.showContainer ?
    "0px 1.58px 11px rgba(44, 39, 56, 0.14)" : "none"};

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 400px;
    margin: 24px;
  }
`;

const EmailButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 327px;
  max-width: 100%;
  padding: 14px;
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
  color: black;
`;
