import React from "react";
import { FormikProps } from "formik";
import {
  Subscribe_Account_EmailError,
  Subscribe_Account_EmailFocus,
  Subscribe_Account_PasswordError,
  Subscribe_Account_PasswordFocus,
} from "../../../services/mixpanel/mixpanel";
import { StyledField } from "../../ui";

export interface EmailPasswordI {
  email: string;
  password: string;
}

const handleMixpanelEvent = (type: string, error?: string) => {
  if (error) {
    if (type === "email") {
      Subscribe_Account_PasswordError();
    }
    if (type === "password") {
      Subscribe_Account_EmailError();
    }
  }
};

export const EmailPasswordForm = ({
  formik,
  emailDisabled,
}: {
  formik: FormikProps<EmailPasswordI>;
  emailDisabled?: boolean;
}) => {
  return (
    <div>
      <StyledField
        name="email"
        type="text"
        width="100%"
        disabled={emailDisabled}
        onFocus={Subscribe_Account_EmailFocus}
        onBlur={e => {
          handleMixpanelEvent("email", formik.errors.email);
          formik.handleBlur(e);
        }}
      />

      <StyledField
        type="password"
        name="password"
        width="100%"
        onFocus={Subscribe_Account_PasswordFocus}
        onBlur={e => {
          handleMixpanelEvent("password", formik.errors.password);
          formik.handleBlur(e);
        }}
      />
    </div>
  );
};
