import * as React from "react";
import styled from "styled-components";
import theme from "../../util/theme";
import LoadingSpinner from "./LoadingSpinner";

class Loading extends React.Component {
  render() {
    return (
      <LoadingSegmentContainer>
        <LoadingImgContainer>
          <LoadingSpinner size={48} color={theme.PRIMARY_COLOR} />
        </LoadingImgContainer>
        <TextDiv>
          <AppText>Loading...</AppText>
        </TextDiv>
      </LoadingSegmentContainer>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

const LoadingSegmentContainer = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
`;

const LoadingImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  margin-top: 10%;
`;

const TextDiv = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

const AppText = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  width: 100%;
  font-size: 30px;
  text-align: center;
`;

export default Loading;
