/* eslint-disable @typescript-eslint/no-unused-vars */
import { navigate } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getProviderData, getUserProfile, logout } from "../../../services/firebase";
import theme from "../../../util/theme";
import Loading from "../../ui/loading";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppText, AppTitle } from "../../ui";
import { track_account_manage_click, track_account_view } from "../../../services/mixpanel/mixpanel";

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    uid: "",
    subscription_status: "",
    management_link: "",
    store: "None",
  });

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const resizeWindow = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    track_account_view();
    setProfile();
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  const setProfile = () => {
    setLoading(true);
    getUserProfile().then(data => {
      if (data) {
        setUser(data);
        setLoading(false);
      }
    });
  };

  const handleManagementLink = () => {
    track_account_manage_click("Manage");
    switch (user.store) {
      case "None":
        navigate("/subscription");
        return;
      case "iOS":
        window.location.assign("https://support.apple.com/en-us/HT204939");
        return;
      case "Stripe":
        window.location.assign(user.management_link);
        return;
      case "Android":
        window.location.assign("https://support.google.com/googleplay/answer/7018481?hl=en");
        return;
      default:
        navigate("/subscription");
    }
  };

  const handleEmailLink = () => {
    track_account_manage_click("EmailEdit");
    navigate("/user/confirm-account?confirm=email");
  };

  const handlePasswordLink = () => {
    track_account_manage_click("PasswordEdit");
    navigate("/user/confirm-account?confirm=password");
  };

  const subscriptionBtnText = (status: string): string =>
    status === "No active subscription" ? "Upgrade" : "Manage";

  const renderBody = () => {
    return (
      <>
        <BodySection>
          <BodyValueContainer>
            <BodyLabel>Email</BodyLabel>
            {getProviderData() === "password" && (
              <EditValue onClick={handleEmailLink}>Edit</EditValue>
            )}
          </BodyValueContainer>
          <BodyValue>{user.email}</BodyValue>
        </BodySection>
        {getProviderData() === "password" && (
          <BodySection>
            <BodyValueContainer>
              <BodyLabel>Password</BodyLabel>
              <EditValue onClick={handlePasswordLink}>Edit</EditValue>
            </BodyValueContainer>
            <BodyValuePassword readOnly type="password" value="password" />
          </BodySection>
        )}
        <BodySection>
          <BodyValueContainer>
            <BodyLabel>Subscription</BodyLabel>
            {user.subscription_status !== "No active subscription" && (
              <EditValue onClick={handleManagementLink}>
                {subscriptionBtnText(user.subscription_status)}
              </EditValue>
            )}
          </BodyValueContainer>
          <BodyValue>{user.subscription_status}</BodyValue>
          {user.subscription_status === "No active subscription" && (
            <BodyValue>
              <small>
                <em>
                  If you purchased a subscription in the Imprint app,
                  please go to the Apple App Store or the Google Play Store
                  to manage your subscription.
                </em>
              </small>
            </BodyValue>
          )}
        </BodySection>
      </>
    );
  };

  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <Container>
      <ContentContainer>
        <AppTitle>Manage your Imprint account</AppTitle>
        <AppText>
          Access and manage your Imprint account including your email, password, and subscription
          settings.
        </AppText>
        <BodyContainer>
          <BodyHeader>Your Account</BodyHeader>
          {loading ? <Loading /> : renderBody()}
        </BodyContainer>

        <ButtonContainer onClick={logout}>
          <AppButton>Log Out</AppButton>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.section`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: fit-content;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 250px;
  margin-bottom: 100px;
  @media ${mobileSize} {
    max-width: 327px;
    margin-top: 100px;
  }
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 40px;
  max-width: 588px;
  background: ${theme.WHITE_COLOR};
  border-radius: 16px;
  padding: 50px 44px;
  @media ${mobileSize} {
    padding: 30px 22px;
  }
`;

const BodyHeader = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${theme.PRIMARY_COLOR};
  text-align: center;
  padding: 0 40px 40px 40px;
  margin: 0;
`;

const BodySection = styled.div`
  margin-bottom: 48px;
  & > * {
    line-height: 25px;
  }
  p {
    margin: 0;
    margin-bottom: 4px;
  }
  @media ${mobileSize} {
  }
`;
const BodyLabel = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 16px;
  color: #8d8794;
  @media ${mobileSize} {
    font-size: 14px;
  }
`;

const BodyValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BodyValue = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;
  color: #524d57;
  @media ${mobileSize} {
    font-size: 16px;
  }
`;

const BodyValuePassword = styled.input`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 28px;
  color: #524d57;
  border: none;
  padding: 0;
  max-width: 100%;
`;

const EditValue = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${theme.PRIMARY_COLOR};
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
