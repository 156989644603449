/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { resetPassword } from "../../../services/firebase";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { AppButton, AppTitle, StyledField } from "../../ui";
import { emailAssertion } from "../../../util/yup-schemas";

const emailSchema = Yup.object().shape({
  email: emailAssertion,
});

const InitialResetForm = ({ setSubmitted }: { setSubmitted: (val: boolean) => void }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values: { email: string }) => {
    const { email } = values;
    try {
      setLoading(true);
      await resetPassword(email);
      setSubmitted(true);
    } catch (err) {
      alert(err.response.data.error);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={emailSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <BodyContainer>
            <StyledField name="email" type="text" width="100%" />
            <ButtonContainer>
              <AppButton type="submit" disabled={loading}>
                Reset Password
              </AppButton>
            </ButtonContainer>
          </BodyContainer>
          <RedirectLink to="/user/login">Back to Log In</RedirectLink>
        </Form>
      )}
    </Formik>
  );
};

const PostResetForm = () => {
  const handleSubmit = () => {
    navigate("/user/login");
  };

  return (
    <BodyContainer>
      <BodySection>
        <ResetConfirmation>
          We sent you an email with a link to reset your password. If you don’t see it, check your
          spam folder or contact us at
          <a href="mailto:info@imprintapp.com"> info@imprintapp.com</a>
        </ResetConfirmation>
      </BodySection>

      <ButtonContainer>
        <AppButton onClick={handleSubmit}>Return to Log In</AppButton>
      </ButtonContainer>
    </BodyContainer>
  );
};

export const ResetPassword = () => {
  const [submitted, setSubmitted] = useState(false);

  console.log(submitted);
  return (
    <Container>
      <ContentContainer>
        <AppTitle>Reset Password</AppTitle>
        {!submitted ? <InitialResetForm setSubmitted={setSubmitted} /> : <PostResetForm />}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  max-height: fit-content;
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 250px;

  @media ${mobileSize} {
    margin-top: 200px;
    max-width: 327px;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 48px;
  max-width: 588px;
  border-radius: 16px;
`;

const BodySection = styled.div`
  margin-bottom: 48px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const RedirectLink = styled(Link)`
  font-family: ${theme.SECONDARY_FONT};
  display: block;
  margin-top: 12px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;

  text-decoration-line: none;
  color: #8d8794;
`;

const ResetConfirmation = styled.p`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  line-height: 25px;
  color: #716b78;
  a {
    color: ${theme.PRIMARY_COLOR};
    text-decoration: none;
  }
`;
