/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link, navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { getUserUID, updateUserEmail } from "../../../services/firebase";
import { BRAZE_API_KEY, BRAZE_SDK_ENDPOINT, mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { AppButton, AppTitle, StyledField } from "../../ui";
import { emailAssertion } from "../../../util/yup-schemas";
import { setMixpanelProperties, trackEvent } from "../../../services/mixpanel/mixpanel";

const userSchema = Yup.object().shape({
  email: emailAssertion,
});

const InitialResetForm = ({ handleData }: { handleData: (email: string) => void }) => {
  const handleSubmit = async (values: { email: string }) => {
    try {
      const { email } = values;
      const newEmail = await updateUserEmail(email);
      if (!newEmail) {
        throw new Error("Something went wrong");
      }

      trackEvent("WebEmail_Update_Email_Success");
      await import("../../../services/braze").then(({ initialize, changeUser, getUser, openSession }) => {
        const uid = getUserUID();
        if (uid) {
          initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
          openSession();
          changeUser(uid);
          getUser()?.setEmail(email);
          console.log("Braze user and email has been set");
        }
      });
      setMixpanelProperties({
        $email: newEmail,
        $name: newEmail,
      });
      handleData(newEmail);
    } catch (err) {
      console.error(err);
      trackEvent("WebEmail_Update_Email_Failure");
      alert(err.message);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={userSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <BodyContainer>
            <StyledField name="email" type="text" width="100%" />
            <ButtonContainer>
              <AppButton type="submit">Update Email</AppButton>
            </ButtonContainer>
          </BodyContainer>
          <RedirectLink to="/user/profile">Back to Account</RedirectLink>
        </Form>
      )}
    </Formik>
  );
};

const PostResetForm = ({ email }: { email: string }) => {
  const handleSubmit = () => {
    navigate("/user/login");
  };

  return (
    <BodyContainer>
      <BodySection>
        <ResetConfimration>
          All set! Your email has been successfully updated to {email}
        </ResetConfimration>
      </BodySection>

      <ButtonContainer>
        <AppButton onClick={handleSubmit}>Return to Log In</AppButton>
      </ButtonContainer>
    </BodyContainer>
  );
};

export const UpdateEmailForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (email: string) => {
    setEmail(email);
    setSubmitted(true);
  };

  console.log(submitted);
  return (
    <Container>
      <ContentContainer>
        <AppTitle>Update Email</AppTitle>
        {!submitted ? (
          <InitialResetForm handleData={handleSubmit} />
        ) : (
          <PostResetForm email={email} />
        )}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  max-height: fit-content;
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 250px;
  @media ${mobileSize} {
    margin-top: 200px;
    max-width: 327px;
  }
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 48px;
  max-width: 588px;
  border-radius: 16px;
`;

const BodySection = styled.div`
  margin-bottom: 60px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
`;

const RedirectLink = styled(Link)`
  font-family: ${theme.SECONDARY_FONT};
  display: block;
  margin-top: 16px;
  font-weight: 700;
  font-size: 12px;
  text-align: center;

  text-decoration-line: none;
  color: #8d8794;
`;

const ResetConfimration = styled.p`
  font-family: ${theme.SECONDARY_FONT};
  font-size: 20px;
  line-height: 25px;
  color: #716b78;
  a {
    text-decoration: none;
  }
`;
