import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { signInWithApple, signInWithGoogle } from "../../../services/firebase-sso";
import {
  track_apple_login_start,
  track_google_login_start,
} from "../../../services/mixpanel/mixpanel";
import theme from "../../../util/theme";
import { LogInForm } from "../../ui/LogInForm";

export const LoginPage = () => {
  const handleGoogleLogin = async () => {
    track_google_login_start({
      page: "homepage",
    });
    const res = await signInWithGoogle();
    if (res) {
      navigate("/user/profile");
    }
  };

  const handleAppleLogin = async () => {
    track_apple_login_start({
      page: "homepage",
    });
    const res = await signInWithApple();
    if (res) {
      navigate("/user/profile");
    }
  };

  const handleEmailLogin = () => {
    navigate("/user/login/email");
  };

  return (
    <Wrapper>
      <LogInForm
        title=""
        showContainer={false}
        emailLabel="Sign in with Email"
        handleEmailLogin={handleEmailLogin}
        handleAppleLogin={handleAppleLogin}
        handleGoogleLogin={handleGoogleLogin}
      />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.GREY_COLOR};
  padding: 16px;
`;
