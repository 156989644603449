/* eslint-disable @typescript-eslint/no-unused-vars */
import { Form, Formik, FormikProps } from "formik";
import { navigate } from "gatsby";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { getUserEmail, login } from "../../../services/firebase";
import {
  track_email_login_confirm_start,
  track_email_login_confirm_fail,
  track_email_login_confirm_success,
} from "../../../services/mixpanel/mixpanel";
import { mobileSize } from "../../../util/variables";
import { AppLoadingButton, AppTitle } from "../../ui";
import { existingUserSchema } from "../../../util/yup-schemas";
import { EmailPasswordForm, EmailPasswordI } from "./EmailPasswordForm";

export const ConfirmAccountForm = () => {
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const confirmParam = params.get("confirm");

  const handleSubmit = async (values: EmailPasswordI) => {
    setLoading(true);
    const { email, password } = values;
    try {
      track_email_login_confirm_start({
        page: "homepage",
      });
      const res = await login({ email, password });
      track_email_login_confirm_success({
        page: "homepage",
      });
      navigate(confirmParam === "password" ? "/user/updatePassword" : "/user/updateEmail");
    } catch (err) {
      track_email_login_confirm_fail({
        page: "homepage",
        error: err,
      });
      alert(err);
    } finally {
      setLoading(false);
    }
  };

  const readyForSubmit = (formik: FormikProps<EmailPasswordI>) => {
    const { email, password } = formik.values;
    const { email: emailError, password: passwordError } = formik.errors;
    return email && password && !emailError && !passwordError;
  };

  const header = "Confirm Account";

  return (
    <Container>
      <ContentContainer>
        <AppTitle>{header}</AppTitle>
        <Formik
          initialValues={{
            email: getUserEmail(),
            password: "",
          }}
          validationSchema={existingUserSchema}
          onSubmit={handleSubmit}
        >
          {(formik: FormikProps<EmailPasswordI>) => (
            <Form>
              <EmailPasswordForm emailDisabled formik={formik} />
              <AppLoadingButton
                type="submit"
                loading={loading}
                disabled={loading || !readyForSubmit(formik)}
                style={{ margin: "40px auto 0 auto" }}
              >
                {header}
              </AppLoadingButton>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 800px;
  max-height: fit-content;
  height: 100vh;
  width: 100%;
`;

const ContentContainer = styled.section`
  max-width: 588px;
  margin: auto;
  margin-top: 250px;

  @media ${mobileSize} {
    margin-top: 180px;

    max-width: 327px;
  }
`;
