import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { OAuthProvider } from "firebase/auth";
import { auth } from "./firebase";
import { track_apple_signin_fail, track_apple_signin_success, track_google_signin_fail, track_google_signin_success } from "./mixpanel/mixpanel";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("email");

export const signInWithGoogle = async () => {
  if (!auth) {
    return false;
  }

  return signInWithPopup(auth, googleProvider)
    .then(result => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.idToken;
      // The signed-in user info.
      const { user } = result;
      console.log("user:", user);
      if (!token || !user.email) {
        console.error(`token or user not found ${token} ${user.email}`);
      }

      track_google_signin_success();
      return result;
    })
    .catch(error => {
      // Handle Errors here.
      track_google_signin_fail();
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const { email } = error.customData;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      if (errorCode === "auth/popup-closed-by-user") {
        console.log("Sign-in popup was closed by the user.");
        return false;
      } else if (errorCode === "auth/cancelled-popup-request") {
        console.log("Pop-up request cancelled.");
        return false;
      } else {
        alert("Failed to login with Google");
        console.error(
          `Failed Google login with email (${email}) ${errorCode}: ${errorMessage} (AuthCredential type: ${credential})`,
        );
        return false;
      }
    });
};

export const signInWithApple = async () => {
  if (!auth) {
    return false;
  }

  return signInWithPopup(auth, appleProvider)
    .then(result => {
      // The signed-in user info.
      // const { user } = result;
      // Apple credential
      // const credential = OAuthProvider.credentialFromResult(result);
      track_apple_signin_success();
      return result;
    })
    .catch(error => {
      track_apple_signin_fail();
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const { email } = error.customData;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.log(errorCode);
      if (errorCode === "auth/popup-closed-by-user") {
        console.log("Sign-in popup was closed by the user.");
        return false;
      } else if (errorCode === "auth/cancelled-popup-request") {
        console.log("Pop-up request cancelled.");
        return false;
      } else {
        alert("Failed to login with Apple");
        console.error(
          `Failed Apple login with email (${email}) ${errorCode}: ${errorMessage} (AuthCredential type: ${credential})`,
        );
        return false;
      }
    });
};
