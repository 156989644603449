import React from "react";
import { navigate } from "gatsby";
import { getUserIdToken } from "../services/firebase";
const UserPublicRoute = ({ component: Component, ...rest }) => {
  const checkUserIsLoggedIn = async () => {
    const token = await getUserIdToken();
    if (token) {
      navigate("/user/profile");
      return null;
    }
  };

  checkUserIsLoggedIn();
  return <Component {...rest} />;
};

export default UserPublicRoute;
