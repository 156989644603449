import React from "react";
import styled from "styled-components";
import GoogleLogo from "../../content/assets/icons/google-logo.svg";
import { signInWithGoogle } from "../../services/firebase-sso";
import {
  sso_create_account_fail,
  sso_create_account_start,
  sso_create_account_success,
  sso_tapped,
} from "../../services/mixpanel/SSO-events";
import { User } from "firebase/auth";

interface GoogleSSOButtonProps {
  text?: string;
  onClick?: () => void;
  size?: "small" | "medium";
  beforeLogin?: () => void;
  onLoginSuccess?: (user: User) => void;
  onLoginFail?: () => void;
  autoHandleAnalytics?: {
    webflow: string;
    properties?: object;
  };
}

export const GoogleSSOButton: React.FC<GoogleSSOButtonProps> = ({
  text,
  onClick,
  size = "medium",
  beforeLogin,
  onLoginSuccess,
  onLoginFail,
  autoHandleAnalytics,
}) => {
  const handleGoogleLogin = async () => {
    beforeLogin?.();
    if (autoHandleAnalytics) {
      sso_tapped("google", autoHandleAnalytics.webflow, autoHandleAnalytics.properties);
      sso_create_account_start(
        "google",
        autoHandleAnalytics.webflow,
        autoHandleAnalytics.properties,
      );
    }

    const res = await signInWithGoogle();
    // eslint-disable-next-line no-negated-condition
    if (typeof res !== "boolean") {
      if (autoHandleAnalytics) {
        sso_create_account_success(
          "google",
          autoHandleAnalytics.webflow,
          autoHandleAnalytics.properties,
        );
      }

      onLoginSuccess?.(res.user);
    } else {
      if (autoHandleAnalytics) {
        sso_create_account_fail(
          "google",
          autoHandleAnalytics.webflow,
          autoHandleAnalytics.properties,
        );
      }

      onLoginFail?.();
    }
  };

  return (
    <StyledButton size={size} onClick={onClick ?? handleGoogleLogin}>
      <img src={GoogleLogo} alt="google" />
      {text}
    </StyledButton>
  );
};

GoogleSSOButton.defaultProps = {
  text: "Sign in with Google",
};

const StyledButton = styled.button<{ size: "small" | "medium" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => (size === "medium" ? "12px" : "6px")};
  width: ${({ size }) => (size === "medium" ? "327px" : "185px")};
  max-width: 100%;
  padding: ${({ size }) => (size === "medium" ? "14px" : "3px")};
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
  line-height: ${({ size }) => (size === "medium" ? "inherit" : "22px")};
  font-weight: ${({ size }) => (size === "medium" ? "inherit" : "500")};
  font-size: ${({ size }) => (size === "medium" ? "inherit" : "17px")};
  height: ${({ size }) => (size === "medium" ? "inherit" : "29px")};
`;
