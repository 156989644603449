import { trackEvent } from "./mixpanel";

export const sso_tapped = (provider: "google" | "apple", webflow: string, properties: object = {}) => {
  trackEvent(`${webflow}_${provider}_SSO_Tapped`, {
    Provider: provider,
    ...properties,
  }, webflow);
};

export const sso_create_account_start = (provider: "google" | "apple", webflow: string, properties: object = {}) => {
  trackEvent(`${webflow}_${provider}_CreateAccountSubmit_Start`, {
    Provider: provider,
    ...properties,
  }, webflow);
};

export const sso_create_account_success = (provider: "google" | "apple", webflow: string, properties: object = {}) => {
  trackEvent(`${webflow}_${provider}_CreateAccountSubmit_Success`, {
    Provider: provider,
    ...properties,
  }, webflow);
};

export const sso_create_account_fail = (provider: "google" | "apple", webflow: string, errorMessage: string, properties: object = {}) => {
  trackEvent(`${webflow}_${provider}_CreateAccountSubmit_Fail`, {
    Provider: provider,
    ...properties,
    errorMessage,
  }, webflow);
};

